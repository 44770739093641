import React, { useState } from "react";
import { graphql } from 'gatsby'
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Table } from "react-bootstrap";
import parse from 'html-react-parser';
import { sitename } from "@constants";

import SEO from "../components/seo"
import Header from "../components/Header/Header"
import Modules from '../components/modules'
import Footer from "../components/Footer/Footer"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import StaticBannerVideo from "../components/StaticPage/AreaGuideBanner"
import SimilarAreas from "../components/ProperyDetails/SimilarProperties/SimilarAreas"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';
import JoinUs from "../components/ProperyDetails/JoinUs/JoinUs";
import FeaturedProperties from "../components/ProperyDetails/SimilarProperties/OfficeFeaturedProperties"
import Reviews from "../components/Home/Reviews/Reviews"
import Choice from "../components/Home/Choice/Choice"
import ServicesOne from "../components/Home/Services/ServicesOne"
import TeamSlider from "../components/Home/Services/TeamSlider"
import Contact from "../components/Home/ContactUs/Contact"
import Stats from "../components/Home/Stats/Stats"
import ImageAndVideoBlock from "../components/Home/ImageAndVideoBlock/ImageAndVideoBlock";
import ImageWithReview from "../components/Home/ImageAndVideoBlock/ImageWithReview";
import NewsBlock from "../components/Home/NewsBlock/NewsBlock"
import ImageBlock from "../components/Home/ImageBlock/ImageBlock";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'

import PlayVideo from "../components/Play/custom-video";
import MailForm from "../components/Team/team-landing-form"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

import { Helmet } from "react-helmet";
import schemaLogo from "../images/schema/schema-logo.png";
import { useLocation } from "@reach/router";

const OfficeDetailsTemplate = (props) => {

  const [isPlay, setPlay] = useState(false);
  const GQLPage = props.data.glstrapi?.office;
  const Office = props.data.glstrapi?.officeDetail;
  //   const OtherAreas = props.data.glstrapi?.areaGuides;

  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat'] = GQLPage.Latitude
  mapItem['lng'] = GQLPage.Longitude
  mapItem['id'] = GQLPage.id
  const mapMarkerImageShowFlag = 0;
  mapItems.push(mapItem);

  let custom_css = ''
  if (GQLPage?.Custom_CSS_Class) {
    custom_css = GQLPage?.Custom_CSS_Class
  }

  const image_url = GQLPage.Full_Image ? GQLPage.Full_Image.url : GQLPage.Image.url

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms?.Image_Transforms) {
    processedImages = GQLPage.imagetransforms.Image_Transforms;
  }

  const getBreadcrumb = (list) => {
    let bread = [
      { Title: "Contact", Slug: "contact/" },
      { Title: GQLPage.Title, Slug: `contact/${GQLPage.URL}` }
    ]
    return bread;
  }
  
  const {pathname} = useLocation();
  var officeUrl = process.env.GATSBY_SITE_URL + pathname
  var metaTitle = GQLPage?.Meta_Title 
  var metaDesc = GQLPage?.Meta_Description
  var addval = GQLPage?.Address.split(",")
  var postalCode = addval[addval.length - 1]
  //console.log ("GQLPage", GQLPage,addval,postalCode)
 
  return (
    <div className={custom_css}>
      <div className="office-details">

        <Helmet>
            <script type="application/ld+json">
              {`{
              "@context": "http://schema.org",
              "@type": "Organization",
              "@id": "https://www.redbrik.co.uk/#Organization",
              "name": "Redbrik Estate Agents",
              "url": "https://www.redbrik.co.uk/",
              "logo": "https://www.redbrik.co.uk/images/logo.png",
              "description": "${metaDesc}",
              "foundingDate": "2013",
              "department": [
              {
                "@type": "RealEstateAgent",
                "@id": "${officeUrl}#RealEstateAgent",
                "url": "${officeUrl}",
                "name": "Redbrik ${GQLPage?.Sub_Title}",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "${GQLPage?.Schema_Rating_Val}",
                  "bestRating": "5",
                  "worstRating": "${GQLPage?.Schema_Worst_Count}",
                  "reviewCount": "${GQLPage?.Schema_Review_Count}"
                },
                "openingHours":["Mon-Fri 09:00am-05:30pm", "Sat 09:00am-04:00pm"],
                "priceRange" : "££",
                "email": ["hello@redbrik.co.uk", "lettings@redbrik.co.uk", "newhomes@redbrik.co.uk"],
                "telephone": ["${GQLPage?.Primary_Phone}", "${GQLPage?.Lettings_Phone ? GQLPage?.Lettings_Phone : GQLPage?.Chesterfield_New_Homes_Phone}", "${GQLPage?.Sales_Phone ? GQLPage?.Sales_Phone : GQLPage?.Sheffield_New_Homes_Phone}"],
                "hasMap": "${GQLPage?.Schema_Map_Val}",
                "address": {
                "@type": "PostalAddress",
                "streetAddress": "${addval.slice(0, -2)}",
                "addressLocality": "Sheffield",
                "postalCode": "${postalCode}",
                "addressCountry": "UK"},
                "location": {
                  "@type": "Place",
                  "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "${GQLPage?.Latitude}",
                    "longitude": "${GQLPage?.Longitude}"
                   }
                  },
              "sameAs" : ["https://www.facebook.com/redbrikestateagents", "https://twitter.com/REDBRIKproperty", "https://www.linkedin.com/company/redbrik-estate-agents-llp"]
              }]            
          }`}
            </script>
        </Helmet>

        <SEO
          title={metaTitle}
          description={metaDesc}
        />
        <Header />

        <div className="office-main-block">
          <Breadcrumb staticList={getBreadcrumb()} />
          <div className="block">
            <div className="office-banner">
              <div className="img-wrapper-container">
                <div className="image">
                  <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Image.main" attr={{ alt: GQLPage.Title + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={GQLPage.id} />
                  {GQLPage?.Video_URL && (
                    <div
                      className="video-buttons"
                      onClick={(e) => {
                        setPlay(true);
                      }}
                    >
                      <PlayVideo url={GQLPage.Video_URL} />
                    </div>
                  )}
                </div>
              </div>

              <div className="content-container">
                <div className="content">
                  <h1>{`${GQLPage.Sub_Title}`}</h1>
                  <div className="address">
                  <i class="icon-map-ping"></i>
                    {GQLPage.Address}
                  </div>

                  <div className="btn-wrapper btns">
                    <Link to="/property-valuation" className="btn btn-black">Book a valuation</Link>
                    {/* <MailForm name={GQLPage.Title} class="btn" email={GQLPage.Primary_Email} tag="office-detail" formLabel="Contact - Office" /> */}
                  </div>
                  <div className="contact-details">
                    <div className="phone">
                      {GQLPage.Sales_Phone && <>
                      <div className="list">
                        <div className="title">Sales</div>
                        {GQLPage.Sales_Phone &&
                          <div><a href={`tel:${GQLPage.Sales_Phone}`} className="contact-no">{GQLPage.Sales_Phone}</a></div>
                        }
                        {GQLPage.Sales_Email &&
                          <div><MailForm name={GQLPage.Title} class="contact-no email" email={GQLPage.Sales_Email} tag="office-1" formLabel="Contact Office - Sales" /></div>
                        }
                      </div>
                      </> }
                      {GQLPage.Lettings_Phone && <>
                      <div className="list">
                        <div className="title">Lettings</div>
                        {GQLPage.Lettings_Phone &&
                          <div><a href={`tel:${GQLPage.Lettings_Phone}`} className="contact-no">{GQLPage.Lettings_Phone}</a></div>
                        }
                        {GQLPage.Lettings_Email &&
                          <div><MailForm name={GQLPage.Title} class="contact-no email" email={GQLPage.Lettings_Email} tag="office-1" formLabel="Contact Office - Lettings" /></div>
                        }
                      </div>
                      </> }
                      {GQLPage.New_Homes_Phone && <>
                      <div className="list">
                        <div className="title">New Homes</div>
                        {GQLPage.New_Homes_Phone &&
                          <div><a href={`tel:${GQLPage.New_Homes_Phone}`} className="contact-no">{GQLPage.New_Homes_Phone}</a></div>
                        }
                        {GQLPage.New_Homes_Email &&
                          <div><MailForm name={GQLPage.Title} class="contact-no email" email={GQLPage.New_Homes_Email} tag="office-1" formLabel="Contact Office - New Homes" /></div>
                        }

                      </div>
                      </> }

                      {GQLPage.Chesterfield_New_Homes_Phone && <>
                      <div className="list">
                        <div className="title">Chesterfield New Homes</div>
                        {GQLPage.Chesterfield_New_Homes_Phone &&
                          <div><a href={`tel:${GQLPage.Chesterfield_New_Homes_Phone}`} className="contact-no">{GQLPage.Chesterfield_New_Homes_Phone}</a></div>
                        }
                        {GQLPage.Chesterfield_New_Homes_Email &&
                          <div><MailForm name={GQLPage.Title} class="contact-no email" email={GQLPage.Chesterfield_New_Homes_Email} tag="office-1" formLabel="Contact Office - Chesterfield New Homes" /></div>
                        }

                      </div>
                      </> }

                      {GQLPage.Sheffield_New_Homes_Phone && <>
                      <div className="list">
                        <div className="title">Sheffield New Homes</div>
                        {GQLPage.Sheffield_New_Homes_Phone &&
                          <div><a href={`tel:${GQLPage.Sheffield_New_Homes_Phone}`} className="contact-no">{GQLPage.Sheffield_New_Homes_Phone}</a></div>
                        }
                        {GQLPage.Sheffield_New_Homes_Email &&
                          <div><MailForm name={GQLPage.Title} class="contact-no email" email={GQLPage.Sheffield_New_Homes_Email} tag="office-1" formLabel="Contact Office - Sheffield New Homes" /></div>
                        }

                      </div>
                      </> }
                    </div>

                    {GQLPage.Office_Timing &&
                      <div className="timing">
                        <div className="title">Opening Hours</div>
                        <div className="list">
                          {GQLPage.Office_Timing.map((time, i) => {
                            return (
                              <>
                                <td className="timing-title">{time.Day}: <span>{time.Timing}</span></td>
                              </>

                            )
                          })}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {GQLPage.Service_Block &&
          <ImageBlock Pagename={props?.page?.Pagename} pageId={GQLPage.id} imagetransforms={GQLPage?.imagetransforms} {...GQLPage.Service_Block}/>
        }
        {/* {Office.Meet_Our_Team &&
          <Choice {...Office.Meet_Our_Team} />
        } */}
        {/* <Stats Intro={``} Stats={Office.Stats} /> */}
        {(GQLPage.Latitude && GQLPage.Longitude) && (
          <div className="area-guides-details-map" id="area-guides-landing-map">
            <LocRatingMap data={mapItems} />
          </div>
        )}

        {/* {GQLPage.Team.length !== 0 &&
          <TeamSlider team={GQLPage.Team} />
        }*/}
        {/*{Office.Services &&
          <ImageAndVideoBlock 
          officeImage={Office.Services.Image}
          pageId={GQLPage.id} 
          imagetransforms={GQLPage?.imagetransforms} 
           {...Office.Services} />
        }*/}

        {/* <JoinUs /> */}

        {/* featured property section start */}
        {/*<FeaturedProperties title={GQLPage.Office_Title} area={GQLPage.URL} className="office-details" />*/}
        {/* featured property section end */}
        {/*{Office.Review &&
          <ImageWithReview 
          officeImage={Office.Review.Image}
          pageLayout={"officePage"}
          pageId={GQLPage.id} 
          imagetransforms={GQLPage?.imagetransforms} 
           {...Office.Review[0]} 
          />
        } */}
        {/* <Reviews /> */}

        {/* {Office.Services_Links &&
          <ServicesOne pageId={GQLPage.id} imagetransforms={GQLPage?.imagetransforms} {...Office.Services_Links} index={4} />
        } */}
        { /* <NewsBlock Title={`In the News`} /> */ }
        {/* {Office.Footer &&
          <Contact pageId={GQLPage.id} imagetransforms={GQLPage?.imagetransforms} imagename="offices.Get_in_Touch_Background_Image.default" {...Office.Footer} />
        } */}

        <Footer popular_search="area" area_name={GQLPage.Title} />
      </div>
    </div>
  )
}

export default OfficeDetailsTemplate





export const pageQuery = graphql`
  query OfficeQuery($slug: ID!) {
    glstrapi {
        office(id: $slug, publicationState: LIVE) {
            Address
            Image {
              url
              alternativeText
            }
            Full_Image {
              url
              alternativeText
            }
            Latitude
            Primary_Email
            Lettings_Email
            Schema_Map_Val
            Schema_Rating_Val
            Schema_Worst_Count
            Schema_Review_Count
            Longitude
            Lettings_Phone
            Primary_Phone
            Meta_Description
            Meta_Title
            New_Homes_Email
            New_Homes_Phone
            Chesterfield_New_Homes_Phone
            Chesterfield_New_Homes_Email
            Sheffield_New_Homes_Phone
            Sheffield_New_Homes_Email
            Sales_Email
            Office_Timing {
              Day
              Timing
            }
            Sales_Phone
            Title
            Sub_Title
            Video_URL
            id
            imagetransforms
            Team {
              id
              Designation
              Name
              URL
              Email
              Image {
                url
                alternativeText
              }
              imagetransforms
            }
            Office_Title
            Custom_CSS_Class
            Service_Block {
              id
              Image {
                alternativeText
                url
              }
              BG_Color
              Image_Align
              Map_Link
              Content
              Custom_Class
              reviews
              Buttons {
                Color
                Icon
                Label
                custom_url
                Link {
                  URL
                  id
                  Main_Parent {
                    URL
                  }
                }
              }
            }
        }

        officeDetail {
            Meet_Our_Team {
              Intro_CTA_Label
              id
              Intro_Info_Title
              Intro_Title
              Intro_Content_Two
              Intro_Content_One
              Intro_CTA_Link {
                id
              }
            }
            Footer {
              Get_in_Touch_CTA_1_Label
              Get_in_Touch_CTA_2_Label
              Get_in_Touch_Content
              id
              Get_in_Touch_CTA_2_Link {
                id
              }
              Get_in_Touch_CTA_1_Link {
                id
              }
              Get_in_Touch_Background_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(placeholder:BLURRED)
                  }
                }
              }
            }
            Services_Links {
              Services_Title
              Services_CTA_Label
              Services_CTA_Link {
                id
              }
              Service_Tile {
                Service_Tile_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(placeholder:BLURRED)
                    }
                  }
                }
                Service_Tile_Label
                Service_Tile_Link {
                  id
                }
              }
            }
            Stats {
              id
              Title
              Suffix
              Prefix
              Info
              CTA_Label
              CTA_Link {
                URL
              }
            }
            Services {
              id
              Video_Url
              Show_Review
              Content
              Image_Info
              Image {
                alternativeText
                url
              }
              Buttons {
                Label
                id
                Color
                Icon
                Link {
                  URL
                }
              }
            }
            Review {
              id
              Video_Url
              Show_Review
              Content
              Image_Info
              Image {
                alternativeText
                url
              }
              Buttons {
                Label
                id
                Color
                Icon
                Link {
                  URL
                }
              }
            }
        }
    }
  }
`

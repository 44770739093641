import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import parse from 'html-react-parser';
import axios from 'axios';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import PropertyRender from "./PropertyRender";
 



import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const FEATURED_PROPERTY = gql`
  query GetProperty ($ptype: String!, $area: String!){
    properties(
      where: {status_in: ["For Sale", "To Let"], publish: true, department: "residential", search_type: $ptype, display_address_contains: $area}
      sort: "available_from:DESC"
      limit: 10
    ) {
      id
      status
      imagetransforms
      images
      price
      price_qualifier
      reception
      slug
      search_type
      title
      crm_id
      department
      display_address
      building
      brochure
      bedroom
      bathroom
      area
    }
  }
`;


const OfficeFeaturedProperties = (props) => {

    console.log(props.className)

    let graphqlParams = { ptype: 'sales', area: "" }
    if (props?.area)
        graphqlParams = { ptype: 'sales', area: props?.area }

    const { loading: saleLoading, error: saleError, data: salepropItems } = useQuery(FEATURED_PROPERTY, {
        variables: graphqlParams
    });

    const { loading: lettingsLoading, error: lettingsError, data: letpropItems } = useQuery(FEATURED_PROPERTY, {
        variables: { ...graphqlParams, ptype: 'lettings' }
    });
    const [activetype, setActivceType] = useState("sales") 
    const [key, setKey] = useState('sale');

    // const [salepropItems, setSalePropItems] = useState(false)
    // const [letpropItems, setLetPropItems] = useState(false)

    // const getitems = async url => {
    //   try {
    //     const { data } = await axios.get(url, {})
    //     setSalePropItems(data)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }
    //
    //
    // const getitemslet = async url => {
    //     try {
    //       const { data } = await axios.get(url, {})
    //       setLetPropItems(data)
    //     } catch (error) {
    //       console.error(error)
    //     }
    //   }

    useEffect(() => {
        // let area = props.area ?? "";
        // let url = process.env.GATSBY_STRAPI_SRC + "/properties?department=residential&search_type=sales&_limit=10&_sort=available_from:DESC&publish=true"; // TODO: base URL supposed to be from .env
        // let let_url = process.env.GATSBY_STRAPI_SRC + "/properties?department=residential&search_type=lettings&_limit=10&_sort=available_from:DESC&publish=true"; // TODO: base URL supposed to be from .env
        // if (area) {
        //   url = url + "&display_address_contains=" + area;
        //   let_url = let_url + "&display_address_contains=" + area;
        // }

        // getitems(url)
        // getitemslet(let_url)
    }, [])

    const settings = { 
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true, 
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true, 
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                },
            },
        ],
    };

    if (saleLoading || lettingsLoading) return null;

    return (
        <React.Fragment>
            {salepropItems?.properties.length || letpropItems?.properties.length ?
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                    <section className={`similar-properties all-featured-properties ${props.className?props.className:''}`}>
                        <Container>
                            <Row>
                                <Col sm={12}>

                                    {/* property heading start */}

                                    {/* property heading end */}
                                    <div className="heading">
                                        <h2>Properties in <span>{props.title}</span></h2>
                                        <div className="type-list">
                                            <ul>
                                                <li className={activetype == "sales" ? "active" : ""} onClick={() => { setActivceType('sales') }}>Sales</li>
                                                <li className={activetype == "lettings" ? "active" : ""} onClick={() => { setActivceType('lettings') }}>Lettings</li> 
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`slick-wrap ${activetype == "sales" ? 'd-block' : 'd-none'}`}>
                                    {salepropItems?.properties.length ?
                                        <Slider {...settings}>

                                        {salepropItems && salepropItems.properties.map((item, index) => {
                                            return (
                                            <PropertyRender item={item} tag={``} />
                                            )
                                        })
                                        }
                                        </Slider> : 'No properties found'
                                    }
                                    </div>
                                    <div className={`slick-wrap ${activetype == "lettings" ? 'd-block' : 'd-none'}`}>
                                    {letpropItems?.properties.length ?
                                        <Slider {...settings}>

                                        {letpropItems && letpropItems.properties.map((item, index) => {
                                            return (
                                            <PropertyRender item={item} tag={``} />
                                            )
                                        })
                                        }
                                        </Slider> : 'No properties found'
                                    }
                                    </div>
                                    {/* <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="office-similar-props"
                                    >
                                        {salepropItems?.properties.length ?
                                            <Tab eventKey="sale" title={`Property for Sale in ${props.title}`}>

                                                <div className="slick-wrap">
                                                    <Slider {...settings}>
                                                        {salepropItems.properties && salepropItems.properties.map((item, index) => {
                                                            // property details url structure
                                                            let uriStr = "";

                                                            if (item.search_type === "sales") {
                                                                uriStr = `property-for-sale-details/`
                                                            } else if (item.search_type === "lettings") {
                                                                uriStr = `property-to-rent-details/`
                                                            }

                                                            if (props.tag == "new-homes") {
                                                                if (item.search_type === "sales") {
                                                                    uriStr = `new-homes-for-sale/`
                                                                } else if (item.search_type === "lettings") {
                                                                    uriStr = `new-homes-to-rent/`
                                                                }
                                                            }
                                                            // property details url structure

                                                            let processedImages = JSON.stringify({});
                                                            if (item?.imagetransforms?.images_Transforms) {
                                                                processedImages = item.imagetransforms.images_Transforms;
                                                            }



                                                            return (
                                                                <div className="properties img-zoom">
                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>

                                                                        {item.images.length > 0 ?
                                                                            <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.display_address + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={item._id} />
                                                                            :
                                                                            <img src={Coming} alt="" className="soon" />
                                                                        }
                                                                    </Link>
                                                                    <div className="card-details">
                                                                        <h3>
                                                                            <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                {item.display_address}
                                                                            </Link>

                                                                        </h3>
                                                                        <h4>
                                                                            <PriceComp {...item} />
                                                                        </h4>
                                                                        <div className="card-info">
                                                                            {item.bedroom &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-bedroom"></i>
                                                                                    </Link>
                                                                                    {item.bedroom}
                                                                                </span>
                                                                            }
                                                                            {item.bathroom &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-bathroom"></i>
                                                                                    </Link>
                                                                                    {item.bathroom}
                                                                                </span>
                                                                            }
                                                                            {item.reception &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-chair"></i>
                                                                                    </Link>
                                                                                    {item.reception}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }

                                                    </Slider>
                                                </div>
                                            </Tab> : ''
                                        }
                                        {letpropItems?.properties.length ?

                                            <Tab eventKey="rent" title={`Property to Rent in ${props.title}`}>

                                                <div className="slick-wrap">
                                                    <Slider {...settings}>
                                                        {letpropItems.properties && letpropItems?.properties.map((item, index) => {
                                                            // property details url structure
                                                            let uriStr = "";

                                                            if (item.search_type === "sales") {
                                                                uriStr = `property-for-sale-details/`
                                                            } else if (item.search_type === "lettings") {
                                                                uriStr = `property-to-rent-details/`
                                                            }

                                                            if (props.tag == "new-homes") {
                                                                if (item.search_type === "sales") {
                                                                    uriStr = `new-homes-for-sale/`
                                                                } else if (item.search_type === "lettings") {
                                                                    uriStr = `new-homes-to-rent/`
                                                                }
                                                            }
                                                            // property details url structure

                                                            let processedImages = JSON.stringify({});
                                                            if (item?.imagetransforms?.images_Transforms) {
                                                                processedImages = item.imagetransforms.images_Transforms;
                                                            }



                                                            return (
                                                                <div className="properties img-zoom">
                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>

                                                                        {item.images.length > 0 &&
                                                                            <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.display_address + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={item._id} />
                                                                        }
                                                                    </Link>
                                                                    <div className="card-details">
                                                                        <h3>
                                                                            <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                {item.display_address}
                                                                            </Link>

                                                                        </h3>
                                                                        {item.price &&
                                                                            <h4>
                                                                                <PriceComp {...item} />
                                                                            </h4>
                                                                        }
                                                                        <div className="card-info">
                                                                            {item.bedroom &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-bedroom"></i>
                                                                                    </Link>
                                                                                    {item.bedroom}
                                                                                </span>
                                                                            }
                                                                            {item.bathroom &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-bathroom"></i>
                                                                                    </Link>
                                                                                    {item.bathroom}
                                                                                </span>
                                                                            }
                                                                            {item.reception &&
                                                                                <span className="details">
                                                                                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                                                                                        <i className="icon-chair"></i>
                                                                                    </Link>
                                                                                    {item.reception}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </Slider>
                                                </div>
                                            </Tab> : ''
                                        }
                                    </Tabs> */}


                                </Col>
                            </Row>
                        </Container>
                    </section>
                </ScrollAnimation> : ''}
        </React.Fragment>
    );
};

export default OfficeFeaturedProperties;
